export const depositTableColumns = [
  {
    _comment: 'This is empty col for expand indication',
    field: 'chevron',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    title: '',
  },
  {
    field: 'requestedAt',
    fieldFormat: "{{requestedAt | amDateFormat:'L LTS'}}",
    filterable: true,
    removable: false,
    show: true,
    sortable: 'requestedAt',
    title: 'common.DATE',
  },
  {
    field: 'transferMethodTypeCode',
    fieldFormat:
      "{{'transferMethodTypes.' + transferMethodTypeCode | translate}}",
    filterable: true,
    removable: false,
    show: true,
    sortable: 'transferMethodTypeCode',
    title: 'common.TYPE',
  },
  {
    field: '',
    fieldName: 'cardHolder',
    fieldFormat: '{{ _calcCardHolder }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    title: 'transaction.CARD_HOLDER',
  },
  {
    field: 'amountRequested',
    fieldFormat:
      '{{amountRequested | currency: currency.code : currency.decimals }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'amountRequested',
    title: 'common.AMOUNTREQUESTED',
  },
  {
    field: 'amountApproved',
    fieldFormat:
      '{{amountApproved | currency: currency.code : currency.decimals }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'amountApproved',
    title: 'common.AMOUNTAPPROVED',
  },
  {
    field: 'transactionTransferCreditCard',
    fieldFormat: '{{transactionTransferCreditCard.cardNumber}}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    title: 'contact.REFERENCE',
  },
  {
    field: 'clearingCompany',
    fieldFormat: '{{ clearingCompany.name }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: false,
    title: 'CLEARING_COMPANY',
  },
  {
    field: 'ewalletOfTransaction',
    fieldFormat: '{{ ewalletTransaction.ewallet.name }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: false,
    title: 'E-WALLET',
  },
  {
    field: 'user',
    fieldFormat: '{{user.firstName}} {{user.lastName}}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'userId',
    title: 'deposits.PROCESSED_BY',
  },
  {
    field: 'ownerDesk',
    fieldFormat: '{{ownerDesk.name}}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'ownerDeskId',
    title: 'deposits.OWNER_DESK',
  },
  {
    field: 'owner',
    fieldName: 'owner',
    fieldFormat: '{{owner.firstName}} {{owner.lastName}}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'owner',
    title: 'transaction.OWNER',
  },
  {
    field: 'transactionStatusCode',
    fieldFormat:
      "{{'transaction.statuses.' + transactionStatusCode.toUpperCase() | translate }}",
    filterable: true,
    removable: false,
    show: true,
    sortable: false,
    title: 'common.STATUS',
  },
  {
    fieldName: 'documents',
    _comment: 'This is empty col for linked deposit attachments',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    title: 'contact.DEPOSIT_DOCUMENTS',
    className: 'prf-th-deposit-documents',
  },
  {
    field: 'note',
    fieldFormat: '{{ note }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    title: 'transaction.NOTES',
  },
  {
    _comment: 'This is empty col for control settings',
    fieldName: 'actions',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    title: '',
  },
];
